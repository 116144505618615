import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Tolerances & Clearances",
  "description": "The machining process, machining tolerances and clearance requirements for design, manufacturing, and assembly.",
  "author": "Nadya Lukman",
  "categories": ["electronics"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": true,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Summarized below are the general clearance requirements and tolerance capabilities of our manufacturing and assembly processes. A selection of copper track widths for different copper weights are also given within.`}</p>
    <h2 {...{
      "id": "clearances"
    }}>{`Clearances`}</h2>
    <p>{`Clearance is the minimum distance required between two points on a PCB to avoid manufacturing, assembly, and general operational failures.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Starting Point`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Target Point`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Clearance`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Test point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Test point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Test point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=2mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Test point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Frame edge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=1mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Test point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Devices with a height greater than 2mm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=3mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Test point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location hole`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=3.2mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Holes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Screw hole or positioning hole`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=1.5mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FPC positioning hole`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pad`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.2-2.5mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hole`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hole`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=0.5mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RF connector`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Devices with a height greater than 2.5mm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=4.1mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RF connector`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixture positioning hole`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=5mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RF connector`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RF connector`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=11mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`mark`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`mark point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=3mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`mark point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Frame edge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=5mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Traces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Traces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jigsaw connection point`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=1mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Traces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Frame edge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=5mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Different for each device`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Golden finger`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=3.8mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=1mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Frame edge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=3mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reflow soldering track edge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=6mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`V-CUT line`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=1mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Devices with a height greater than 9mm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`>=6mm`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The component to component clearance are decided using the rules in the following table.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "951px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.11196911196912%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADDUlEQVQ4y3WQTWxUVRiGv1sIMS6JiWxcWKgjNoaFUWK0UBqHdqYW3bnwl9ISSFiQgGXa+bkz996ZOy0dQMrMFIL9m5mqUYNtohESILow0YRIIEYTAwvjwp9o6NyZ6RRMHnPObSOauHjyfec953vPe45ED6dxYlG+r7SykA8RSVgkknESSZOYGSduJjSWY2OmkiStFCnb0n3KsfXaP5vQmtystLE4GWb+gx6mz72CG3cZNR3GkmldswmbbMIhZ2c5nswwnnIZt1y9r7SxZEbjxm3GzDRy8Xwns6ffIFo4wuHiIXbmBgmeGqDr5CC7Tgxouk4MEDx9gK5Tg5oX3vH74JmDWtdnTqq9/cjbI2PEky6xY2nO5PeT++FhZPpFZL4bKe9epRsp3derWun2WeuVrmZMM4ptmwxFLKbOvslvdwxevboNmenTxutLIYy5MMZsGGMm5Ne1taK0itLKvcrQJJNxGI5YFCb3sdIU/moK8z9u4pGPOpHpPoyyGgghcyFaSiFayj5SDv9DKYxUepFYLEYqZTI0lKJQeIuqJyxVBZrC7d838NrVJ5EZNRTyTdV3KGb7kFKvbzwX1pdpY8dxGB11icUyTE72s7ws1OuC1zBYafhp3/9uE4+eDSNTvTx2YQePf9JB+2IHG95TF+zBKK2aKkPLsshmM4yMOBQKe2k0hFpNdFVJvYbAny1cv7yZ9LXN/LK0jntKuytc/GkjPZef1mbrKyHWzWtDWxsOD9sUi3t1OmWoU3o+dzzh3l3hjyWDfV+2s3Whgy2Lney8tJ2tizuQcy8j7+5Bpl7yn+y6DtFoRhven1DVNdTfKm78+iAHvnqCg1+3s/HDIA99HMT+tpUj3wQ4di2A2LZDLnecSMSiWOxfTdZCvW5QrRp43r9ZqQssCyC411tpvbCLjs+fZfelZ+i5sh2Jx+M4jsXRoyb5/Ov/m3ANr+Z/wVJNaDaEL35+gOc/24ac70amwsjExATFYp7x8TyVyjC1WhueF6BWC1CtBnT/X5Rer/s9zS2s1NpYuPUcT33az9+bHa1SgtdvcQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/ee2ee94af796058227977ec34cc031c2/652f5/tolerances-and-clearances-01.webp 259w", "/static/ee2ee94af796058227977ec34cc031c2/c29d2/tolerances-and-clearances-01.webp 518w", "/static/ee2ee94af796058227977ec34cc031c2/d9249/tolerances-and-clearances-01.webp 951w"],
            "sizes": "(max-width: 951px) 100vw, 951px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/ee2ee94af796058227977ec34cc031c2/a2ead/tolerances-and-clearances-01.png 259w", "/static/ee2ee94af796058227977ec34cc031c2/6b9fd/tolerances-and-clearances-01.png 518w", "/static/ee2ee94af796058227977ec34cc031c2/9b379/tolerances-and-clearances-01.png 951w"],
            "sizes": "(max-width: 951px) 100vw, 951px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/ee2ee94af796058227977ec34cc031c2/9b379/tolerances-and-clearances-01.png",
            "alt": "Component clearance",
            "title": "Component clearance",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "tolerances"
    }}>{`Tolerances`}</h2>
    <p>{`Tolerance is the machining accuracy attainable to produce each feature on a PCB.`}</p>
    <p>{`The standard tolerance for PCB contours, slots, and cut-outs is `}<strong parentName="p">{`±0.15mm`}</strong></p>
    <h3 {...{
      "id": "holes"
    }}>{`Holes`}</h3>
    <p>{`Read more about `}<a parentName="p" {...{
        "href": "/articles/Vias-Holes/"
      }}>{`Vias and Holes`}</a>{` here`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Hole Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Position Tolerance`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Diameter Tolerance`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NPTH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`±0.075mm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`±0.05 ~ ±0.075mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PTH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`±0.075mm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`±0.05 ~ ±0.1mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Vias`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`±0.05mm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`±0.05mm`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "traces"
    }}>{`Traces`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Trace Width`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Tolerance`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`≤10 mil`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`±1.0 mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`>10 mil`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`±1.5 mil`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "solder-mask"
    }}>{`Solder Mask`}</h3>
    <p>{`Solder mask should have an opening with a size bigger than 2 mils, and a bridge greater than 6 mils.`}</p>
    <p>{`The characters to be printed as the silkscreen should have a character line width to character height ratio of 1:5. The commonly used character line sizes are`}</p>
    <ul>
      <li parentName="ul">{`4/20mil,`}</li>
      <li parentName="ul">{`5/25mil,`}</li>
      <li parentName="ul">{`and 6/30mil.`}</li>
    </ul>
    <h3 {...{
      "id": "track-width-choice"
    }}>{`Track Width Choice`}</h3>
    <h4 {...{
      "id": "inner-copper-weight"
    }}>{`Inner Copper Weight`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Inner Copper Weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Track width`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1/2OZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3/3mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1OZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3/4mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2OZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4/5.5mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3OZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5/8mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4OZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6/11mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5OZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7/14mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6OZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8/16mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7OZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9/19mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8OZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10/22mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9OZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11/25mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10OZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12/28mil`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "outer-copper-weight"
    }}>{`Outer Copper Weight`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Outer Copper Weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Track width`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1/3OZ base copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3.5/4mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1/2OZ base copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3.9/4.5mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1OZ base copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4.8/5.5mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1.43OZ base copper (positive film)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4.5/7`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1.43OZ base copper (negative film)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`44413`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2OZ base copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6/8mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3OZ base copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6/12mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4OZ base copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7.5/15mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5OZ base copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9/18mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6OZ base copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10/21mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7OZ base copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11/25mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8OZ base copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12/29mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9OZ base copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13/33mil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10OZ base copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14/38mil`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      